import Chart from 'chart.js/auto';
import { getRelativePosition } from 'chart.js/helpers';

var priorityToHuman = function(value) {
  switch (value) {
    case 'low_priority':
      return 'Faible';
    case 'medium_priority':
      return 'Moyen';
    case 'ok_priority':
      return 'Adéquat';
    case 'high_priority':
      return 'Élevé';
    case 'maximum_prioriry':
      return 'Maximum';
    default:

  }
}

var motivationToHuman = function(value) {
  switch (value) {
    case 'low_motivation':
      return 'Faible';
    case 'medium_motivation':
      return 'Moyen';
    case 'ok_motivation':
      return 'Adéquat';
    case 'high_motivation':
      return 'Élevé';
    case 'maximum_motivation':
      return 'Maximum';
    default:

  }
}


var addChartForMotivationAndPriority = function(ctx) {
  // Configuring helper functions to change low_priority to 1 (as an example), and vice versa.
  // TODO: this code should not run for every chart...it's not a huge deal, though. Leaving it as-is for now.
  window.priorityLegend = JSON.parse(ctx.dataset.priorities)
  window.motivationLegend = JSON.parse(ctx.dataset.motivations)
  window.motivationLegendReserse = {}

  for (var key in motivationLegend) {
    var value = motivationLegend[key];
    window.motivationLegendReserse[value] = key;
  }
  window.priorityLegendReverse = {}
  for (var key in priorityLegend) {
    var value = priorityLegend[key];
    window.priorityLegendReverse[value] = key;
  }

  var options = {
    responsive: true,
    hoverMode: 'index',
    stacked: false,
    interaction: {
      intersect: false,
    },
    elements: {
      line: {
        tension: 0
      }
    },
    tooltips: {
      callbacks: {
        label: function(tooltipItem, data) {
          // configure labels, so that they don't show something like "Priority: low_priority" which is the default
          var label = data.datasets[tooltipItem.datasetIndex].label || '';

          if (label) {
            label += ': ';
          }

          if (tooltipItem.datasetIndex == 0) {
            label += priorityToHuman(priorityLegendReverse[tooltipItem.yLabel]).toLowerCase();
            return label
          }

          if (tooltipItem.datasetIndex == 1) {
            label += motivationToHuman(motivationLegendReserse[tooltipItem.yLabel]).toLowerCase();
            return label
          }

          label += Math.round(tooltipItem.yLabel * 100) / 100;

          return label;
        }
      }
    },
    legend: {
      position: 'bottom'
    },
    scales: {
      y: {
        type: 'linear', // only linear but allow scale type registration. This allows extensions to exist solely for log scale for instance
        display: true,
        position: 'left',
        id: 'y-axis-2',
        // configure the scale and the points
        ticks: {
          min: 1,
          max: 90,
          fontFamily: 'Rubik, BlinkMacSystemFont, -apple-system, "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", "Helvetica", "Arial", sans-serif',
          fontColor: 'rgb(167, 190, 229)',
          fontSize: 15,
          callback: function(value, index, values) {
            return priorityToHuman(priorityLegendReverse[value]);
          }
        },
        scaleLabel: {
          display: false,
          labelString: 'Priorisation / Motivation',
          fontFamily: 'Rubik, BlinkMacSystemFont, -apple-system, "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", "Helvetica", "Arial", sans-serif',
          fontColor: 'rgb(167, 190, 229)',
          fontSize: 15
        },

        // grid line settings
        gridLines: {
          drawOnChartArea: false, // only want the grid lines for one axis to show up
        },
      },
      x: {
        ticks: {
          fontFamily: 'Rubik, BlinkMacSystemFont, -apple-system, "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", "Helvetica", "Arial", sans-serif',
          fontColor: 'rgb(167, 190, 229)',
          fontSize: 15
        },
        gridLines: {
          drawOnChartArea: false
        },
        scaleLabel:{
          fontFamily: 'Rubik, BlinkMacSystemFont, -apple-system, "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", "Helvetica", "Arial", sans-serif',
          fontColor: 'rgb(167, 190, 229)',
          fontSize: 15
        }
      }
    }
  }

  // prepare data out of the DOM
  var allData = JSON.parse(ctx.dataset.chartData)

  var labels = allData.map(function(item) {
    return item[0]
  })
  console.log(labels);
  var priorityData = allData.map(function(item) {
    return window.priorityLegend[item[1]]
  })
  var motivationData = allData.map(function(item) {
    return window.motivationLegend[item[2]]
  })


  // Gradients definitions
  var drawCtx = ctx.getContext('2d');
  var priorityGradient = drawCtx.createLinearGradient(0, 0, 0, 400);
  priorityGradient.addColorStop(0, 'rgba(60, 195, 223, 0.30)'); // 30% opacity at top
  priorityGradient.addColorStop(1, 'rgba(60, 195, 223, 0.05)'); // 5% opacity at bottom

  var motivationGradient = drawCtx.createLinearGradient(0, 0, 0, 400);
  motivationGradient.addColorStop(0, 'rgba(137, 121, 255, 0.30)'); // 30% opacity at top
  motivationGradient.addColorStop(1, 'rgba(137, 121, 255, 0.05)'); // 5% opacity at bottom
  
  // Configure datasets
  var lineChartData = {
    labels: labels,
    datasets: [{
      label: 'Priorisation',
      borderColor: 'rgb(46, 91, 255)',
      backgroundColor: priorityGradient,
      pointBackgroundColor: 'rgb(255,255,255)',
      pointBorderColor: 'rgb(46, 91, 255)',
      borderWidth: 2,
      pointBorderWidth: 2,
      fill: 'origin',
      data: priorityData,
      tension: 0.4,  // Add smooth curves
      cubicInterpolationMode: 'monotone',  // Ensures smooth transitions
    }, {
      label: 'Motivation',
      borderColor: 'rgb(140, 84, 255)',
      backgroundColor: motivationGradient,
      pointBackgroundColor: 'rgb(255,255,255)',
      pointBorderColor: 'rgb(140, 84, 255)',
      borderWidth: 2,
      pointBorderWidth: 2,
      fill: 'origin',
      data: motivationData,
      tension: 0.4,  // Add smooth curves
      cubicInterpolationMode: 'monotone',  // Ensures smooth transitions
    }]
  };

  // create chart
  var lineChart = new Chart(ctx, {
    labels: labels,
    type: 'line',
    data: lineChartData,
    options: options
  });

  var goalForm = ctx.parentElement.parentElement.parentElement.parentElement.getElementsByClassName('goal-update-form')[0]
  // not all charts are in boxes with a form to update a goal
  if(goalForm != null) {
    goalForm.addEventListener('goalCheckpointAdded', function(event){
      var rightNow = new Date();
      var label = rightNow.toISOString().slice(0,10).replace(/-/g,"-");
      this.data.labels.push(label)

      this.data.datasets[0].data.push(window.priorityLegend[event.detail.priority])
      this.data.datasets[1].data.push(window.motivationLegend[event.detail.motivation])

      this.update({
          duration: 800,
          lazy: false,
          easing: 'easeOutBounce'
      })
    }.bind(lineChart))
  }

}
var addChartForResult = function(ctx) {
  var options = {
    responsive: true,
    hoverMode: 'index',
    stacked: false,
    elements: {
      line: {
        tension: 0
      }
    },
    legend: {
      position: 'bottom'
    },
    scales: {
      y: {
        ticks: {
          fontFamily: 'Rubik, BlinkMacSystemFont, -apple-system, "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", "Helvetica", "Arial", sans-serif',
          fontColor: 'rgb(167, 190, 229)',
          fontSize: 15,
          callback: function(value, index, values) {
            console.log(ctx.dataset, 'ctx.dataset');
            console.log(value, 'value');
            console.log(values, 'values');
            return value + ' ' + ctx.dataset.targetUnit;
          }
        },
        type: 'linear', // only linear but allow scale type registration. This allows extensions to exist solely for log scale for instance
        display: true,
        position: 'left',
        id: 'y-axis-1',
        scaleLabel: {
          display: false,
          labelString: 'Résultat',
          fontFamily: 'Rubik, BlinkMacSystemFont, -apple-system, "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", "Helvetica", "Arial", sans-serif',
          fontColor: 'rgb(167, 190, 229)',
          fontSize: 15
        },

        // grid line settings
        gridLines: {
          drawOnChartArea: false, // only want the grid lines for one axis to show up
        },
      },
      x: {
        ticks: {
          fontFamily: 'Rubik, BlinkMacSystemFont, -apple-system, "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", "Helvetica", "Arial", sans-serif',
          fontColor: 'rgb(167, 190, 229)',
          fontSize: 15
        },
        gridLines: {
          drawOnChartArea: false
        },
        scaleLabel:{
          fontFamily: 'Rubik, BlinkMacSystemFont, -apple-system, "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", "Helvetica", "Arial", sans-serif',
          fontColor: 'rgb(167, 190, 229)',
          fontSize: 15
        }
      }
    }
  }

  // prepare data out of the DOM
  var allData = JSON.parse(ctx.dataset.chartData)

  var labels = allData.map(function(item) {
    return item[0]
  })

  var resultData = allData.map(function(item) {
    return item[1]
  })


  var targetData = new Array()
  for (var i = 0, len = resultData.length; i < len; i++) {
    targetData.push(ctx.dataset.targetValue)
  }

  // Gradients definitions
  var drawCtx = ctx.getContext('2d');
  var blueGradient2 = drawCtx.createLinearGradient(0, 0, 0, 400);
  blueGradient2.addColorStop(0, 'rgba(60, 195, 223, 0.30)'); // 30% opacity at top
  blueGradient2.addColorStop(1, 'rgba(60, 195, 223, 0.05)'); // 5% opacity at bottom

  var purpleGradient2 = drawCtx.createLinearGradient(0, 0, 0, 400);
  purpleGradient2.addColorStop(0, 'rgba(140,84,255,0.1)');
  purpleGradient2.addColorStop(0.6, 'rgba(140,84,255,0)');
  // purpleGradient2.addColorStop(0, 'rgba(137, 121, 255, 0.30)'); // 30% opacity at top
  // purpleGradient2.addColorStop(1, 'rgba(137, 121, 255, 0.05)'); // 5% opacity at bottom

  // Configure datasets
  var lineChartData = {
    labels: labels,
    datasets: [{
      label: 'Actuellement',
      borderColor: 'rgb(46, 91, 255)',
      backgroundColor: blueGradient2,
      pointBackgroundColor: 'rgb(255,255,255)',
      pointBorderColor: 'rgb(46, 91, 255)',
      borderWidth: 2,
      pointBorderWidth: 2,
      fill: 'origin',
      data: resultData,
      tension: 0.4,  // Add smooth curves
      cubicInterpolationMode: 'monotone',  // Ensures smooth transitions
    },{
      label: 'Objectif',
      borderColor: 'rgb(140, 84, 255)',
      backgroundColor: purpleGradient2,
      pointBackgroundColor: 'rgb(255,255,255)',
      pointBorderColor: 'rgb(140, 84, 255)',
      borderWidth: 2,
      pointBorderWidth: 2,
      fill: 'origin',
      data: targetData,
      tension: 0.4,  // Add smooth curves
      cubicInterpolationMode: 'monotone',  // Ensures smooth transitions
    }]
  };

  // create chart
  var lineChart = new Chart(ctx, {
    labels: labels,
    type: 'line',
    data: lineChartData,
    options: options
  });

  var goalForm = ctx.parentElement.parentElement.parentElement.parentElement.getElementsByClassName('goal-update-form')[0]
  // not all charts are in boxes with a form to update a goal
  if(goalForm != null) {
    goalForm.addEventListener('goalCheckpointAdded', function(event){
      var rightNow = new Date();
      var label = rightNow.toISOString().slice(0,10).replace(/-/g,"-");
      this.data.labels.push(label)

      this.data.datasets[0].data.push(event.detail.value)
      this.data.datasets[1].data.push(this.canvas.dataset.targetValue)

      this.update({
        duration: 800,
        lazy: false,
        easing: 'easeOutBounce'
      })
    }.bind(lineChart))
  }

}

function bindGraphLoadingOnStreamJs() {
  // Destroy existing charts first
  destroyAllCharts();
  // Initialize new charts
  initializeCharts();
}

function destroyAllCharts() {
  // Destroy motivation/priority charts
  document.querySelectorAll('.goalChartForMotivationAndPriority').forEach(canvas => {
    const existingChart = Chart.getChart(canvas);
    if (existingChart) existingChart.destroy();
  });

  // Destroy result charts
  document.querySelectorAll('.goalChartForResult').forEach(canvas => {
    const existingChart = Chart.getChart(canvas);
    if (existingChart) existingChart.destroy();
  });
}

function initializeCharts() {
  // Initialize motivation/priority charts
  document.querySelectorAll('.goalChartForMotivationAndPriority').forEach(canvas => {
    addChartForMotivationAndPriority(canvas);
  });

  // Initialize result charts
  document.querySelectorAll('.goalChartForResult').forEach(canvas => {
    addChartForResult(canvas);
  });
}

// check if a Turbo Stream targets 'graphs-container'
function isGraphsContainerStream(event) {
  return event.detail.newStream.target == "graphs-container"
}

document.addEventListener("turbo:load", function() {
  bindGraphLoadingOnStreamJs();
});

document.addEventListener("turbo:before-stream-render", function(event) {
  setTimeout(() => {
    if (isGraphsContainerStream(event)) {
      destroyAllCharts();
      initializeCharts();
    }
  }, 500);
});
