document.addEventListener("turbo:load", function() {
  //bindFormationOnTurboLoadJs();
  bindCoachingOnTurboAndStreamJs();
});

document.addEventListener("turbo:before-stream-render", function() {
  setTimeout(() => {
    bindCoachingOnTurboAndStreamJs(); // Rebind accordion logic after each Turbo update
  }, 500);
});

function bindCoachingOnTurboAndStreamJs() {
  // show formation left panel for screen smaller then 1366
  var coachingLeftContainerLink = document.querySelector('#coaching-left-container-link');
  var fermerButtonLink = document.querySelector('#coaching-fermer-button-link');
  var coachingLeftColumn = document.querySelector('.coaching-main-content > .left-column');
  if(coachingLeftContainerLink && coachingLeftColumn) {
    coachingLeftContainerLink.addEventListener('click', function(event) {
      coachingLeftColumn.style.display = 'block';
    });
    fermerButtonLink.addEventListener('click', function(event) {
      coachingLeftColumn.style.display = 'none';
    });
  }

  document.querySelectorAll('.action-filter-button').forEach(button => {
    button.addEventListener('click', (e) => {
      // Remove active class from all buttons
      document.querySelectorAll('.action-filter-button').forEach(b => b.classList.remove('active'));
      // Add active class to clicked button
      e.currentTarget.classList.add('active');
    });
  });
}
