import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="context-menu"
export default class extends Controller {
  connect() {
    var area = document.querySelectorAll('.js_goalContextMenuArea');
    var menuBtn = document.querySelectorAll('.js_goalContextMenuBtn');
    var menu = document.querySelectorAll('.js_goalContextMenu');

    for (var i = 0, len = menuBtn.length; i < len; i++) {
      menuBtn[i].addEventListener('click', function(e) {
        var menu = e.currentTarget.parentElement.querySelector('.js_goalContextMenu');
        if (window.getComputedStyle(menu).display === 'none'){
          return menu.style.display = 'block';
        } else {
          return menu.style.display = 'none';
        }
      });
    }

    for (var i = 0, len = menu.length; i < len; i++) {
      menu[i].addEventListener('mouseleave', function(e) {
        e.currentTarget.style.display = 'none';
      });
    }

    for (var i = 0, len = area.length; i < len; i++) {
      area[i].addEventListener('mouseleave', function(e) {
        var menu = e.currentTarget.parentElement.querySelector('.js_goalContextMenu');
        if(menu) {
          menu.style.display = 'none';
        }
      });
    }
  }
}
