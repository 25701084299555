import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="goal-radio-toggler"
export default class extends Controller {
  static targets = ["targetElement"];


  toggle(event) {
    const value = event.target.value; // Get the value of the clicked radio button
    const targetElement = document.getElementById("no-coach-goal"); // Find the target element
    if (value === 'false') {
      targetElement.classList.remove("hidden"); // Remove the hidden class
    } else if (value === 'true') {
      targetElement.classList.add("hidden"); // Add the hidden class
    }
  }
}
