document.addEventListener('turbo:load', function () {
  window.goalJsPaginators();
});

window.goalJsPaginators = function() {
  var paginators = Array.prototype.slice.call(document.querySelectorAll(".js-paginator"), 0);

  if( paginators.length > 0 ) {
    paginators.forEach(function (paginator) {

      var review = {
        $pages : Array.prototype.slice.call(paginator.querySelectorAll('.js-paginatorPage'), 0),
        $newProgressBar : Array.prototype.slice.call(paginator.querySelectorAll('.js-newPaginatorProgressBar'), 0),
        $newProgressText : Array.prototype.slice.call(paginator.querySelectorAll('.js-newPaginatorProgressText'), 0),
        $progressBar : paginator.querySelector('.js-paginatorProgressBar'),
        $currentPage : paginator.querySelector('.js-paginatorCurrentPage'),
        $countPage : paginator.querySelector('.js-paginatorCountPage'),
        $prevBtn : paginator.querySelector('.js-paginatorPrevBtn'),
        $nextBtn : paginator.querySelector('.js-paginatorNextBtn'),
        $endBtn : paginator.querySelector('.js-paginatorEndBtn'),
      };

      var reviewPageLength = review.$pages.length;
      var reviewPageCurrent = 1 ;

      review.$prevBtn.addEventListener( "click", previousPage );
      review.$nextBtn.addEventListener( "click", nextPage );

      updateProgress();

      function updateProgress () {
        review.$pages[reviewPageCurrent - 1].classList.add('active');

        review.$pages.forEach(function (reviewPage) {
          reviewPage.style.display = 'none';
        });
        review.$pages[reviewPageCurrent - 1].style.display = 'block';
        if (review.$progressBar) {
          review.$progressBar.style.width =  (reviewPageCurrent / reviewPageLength) * 100 + "%";
          review.$currentPage.innerHTML = reviewPageCurrent;
          review.$countPage.innerHTML = reviewPageLength;
        } else if (review.$newProgressBar) {
          review.$newProgressBar.forEach(function (progressBar) {
            progressBar.classList.remove('active');
          });
          review.$newProgressBar[reviewPageCurrent - 1].classList.add('active');
          review.$newProgressText.forEach(function (progressBar) {
            progressBar.classList.remove('active');
          });
          review.$newProgressText[reviewPageCurrent - 1].classList.add('active');
        }
        
    
        if( reviewPageCurrent === 1)  {
          review.$prevBtn.style.display = 'none';
        } else {
          review.$prevBtn.style.display = 'block';
        }
    
        if( reviewPageCurrent === reviewPageLength)  {
          review.$nextBtn.style.display = 'none';
        } else {
          review.$nextBtn.style.display = 'block';
        }

        if( reviewPageCurrent !== reviewPageLength)  {
          review.$endBtn.style.display = 'none';
        } else {
          review.$endBtn.style.display = 'block';
        }
    
      }
    
      function nextPage () {
        reviewPageCurrent ++;
        updateProgress();
      }

      function previousPage () {
        reviewPageCurrent --;
        updateProgress();
      }
    });
  }
};
