import './calendar'; // bulma calendar
import './check_all';
import './conversations';
import './goal-charts';
import './goal-update';
import './mobile-menu';
import './todolist';
import './admins/plan';
import './admins/tax';
import './admins/coupon';
import './articles/form';
import './assessment_questions/form';
import './assessment_questions/nested_form';
import './assessment_sections/form';
import './assignments/form';
import './clients/form';
import './clients/questionnaires_welcome'
import './coaching/coaching'
import './configuration/price_table';
import './configurations/public_profile';
import './configuration/reminder_form';
import './courses/form';
import './courses/print_ceritificate';
import './courses/formations'
import './goals/form';
import './header/header-navbar';
import './invoices/invoices';
import './modules/editableRing';
import './modules/modal-observer';
import './modules/new-objectif-type-switch';
import './modules/paginator';
import './modules/profile-visibility';
import './modules/switch-button';
import './modules/review-flow';
import './nested_forms/input-list';
import './notifications/form';
import './open_vidu/openvidu-browser-2.16.0';
import './payments/payments';
import './questionnaires/form';
import './reports/reports';
import './scheduled_sessions/form';
import './scheduled_sessions/progress';
import './scheduled_sessions/video';
import './tools/form';
import './templates/form';
import './users/form';
import './utilities/admin_grapes';
import './utilities/autosave';
import './utilities/clipboard';
import './utilities/custom-upload';
import './utilities/grapes';
import './utilities/show-hide';
import './utilities/sortable';
import './utilities/submit-on-change';
import './utilities/tagger';
import './utilities/tinymce';
import './vendors/jquery-ui.min';
import './vendors/bulma-slider-output';
import './vendors/bulma-slider-output-firefox-hack';

import LayoutMenubar from './modules/menubar';
var menubar = new LayoutMenubar;
menubar.start();

import ContextMenu from './modules/context-menu';
var ContextMenus = new ContextMenu;
ContextMenus.start();

import FormUtilsShowPassword from './modules/show-password';
var showPassword = new FormUtilsShowPassword;
showPassword.start();

import AllNotesModal from './modals/all-notes-modal'
var allNotesModal = new AllNotesModal();
allNotesModal.start();

import WarningModal from './modals/warning-modal'
var warningModal = new WarningModal();
warningModal.start();

import ExerciseDetailsModal from './modals/details-modal'
var exerciseDetailsModal = new ExerciseDetailsModal();
exerciseDetailsModal.start();

import ToolFilters from './tool-filtering.js';
var toolFilters = new ToolFilters();
toolFilters.start();

import TemplateFilters from './templates/filtering';
var templateFilters = new TemplateFilters();
templateFilters.start();

import TableSortingStyle from './modules/table-sorting-style';
var tableStyle = new TableSortingStyle;
tableStyle.start();

import ExercicesAddingFromProgressScreen from './exercices/adding-from-progress-screen'
var exericesAdd = new ExercicesAddingFromProgressScreen();
exericesAdd.start();

import Tabs from './utilities/tabs';
var tabs = new Tabs();
tabs.start();

import NestedFormsReferrer from './nested_forms/referrer'
var referrer = new NestedFormsReferrer();
referrer.start();

import NestedFormsLearningElement from './nested_forms/learning-elements'
var learningElements = new NestedFormsLearningElement();
learningElements.start();

import NestedFormsTask from './nested_forms/tasks'
var tasks = new NestedFormsTask()
tasks.start();

import NestedFormsLineItem from './nested_forms/line-items';
var lineItems = new NestedFormsLineItem();
lineItems.start();

import NestedFormsDisplayRule from './nested_forms/assessment_display_rules';
var displayRules = new NestedFormsDisplayRule();
displayRules.start();

import ListFilter from './conversations/listFilter.js';
var listFilterHolder = new ListFilter();
listFilterHolder.start();
