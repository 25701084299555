document.addEventListener("turbo:load", function() {
  bindFormationOnTurboLoadJs();
  bindFormationOnTurboAndStreamJs();
});

document.addEventListener("turbo:before-stream-render", function() {
  setTimeout(() => {
    bindFormationOnTurboAndStreamJs(); // Rebind accordion logic after each Turbo update
  }, 500);
});

function handleAccordionClick() {
  var mainFormationContents = document.querySelectorAll('.main-formation-content.formation-content');
  var mainSectionChevronIcons = document.querySelectorAll('.main-formation-content.section-chevron-icon');
  if(this.dataset.confirm == '') {
    const alreadyOpened = this.nextElementSibling.classList.contains('formation-content-active');
    // Close all sections
    mainFormationContents.forEach(function(formationContent) {
      formationContent.classList.remove('formation-content-active');
    });
    // Turn all chevrons upside-down
    mainSectionChevronIcons.forEach(function(sectionChevronIcon) {
      sectionChevronIcon.classList.remove('accordion-transform-50');
    });
    // Open the clicked section and rotate it's chevron
    // UNLESS it was already opened and I clicked on it to close it
    if (!alreadyOpened) {
      this.nextElementSibling.classList.add('formation-content-active');
      this.querySelector('.section-chevron-icon').classList.add('accordion-transform-50');
    }
  }
}

function bindFormationOnTurboLoadJs() {
  // custom accordion
  var accordionHeaders = document.querySelectorAll('.custom-accordion-header');
  if(accordionHeaders) {
    accordionHeaders.forEach(function(accordionHeader){
      accordionHeader.addEventListener('click', function(header) {
        const closetList = this.nextElementSibling;
        const closetChevronIcon = this.querySelector('.accordion-down-icon');

        this.classList.toggle('accordion-header-bottom');
        closetChevronIcon.classList.toggle('accordion-transform-50');
        closetList.classList.toggle('hidden');
      });
    });
  }

  // show/hide coaches list
  var mainContactDropDown = document.querySelector('#main-contact-dropdown');
  var messagesList = document.querySelector('.messages-list');
  var coachesList = document.querySelector('.coaches-list');
  var contactChevron = document.querySelector('.contact-chevron');
  if(mainContactDropDown) {
    mainContactDropDown.addEventListener('click', function(event) {
      messagesList.classList.toggle('hidden');
      coachesList.classList.toggle('hidden');
      contactChevron.classList.toggle('accordion-transform-50');
    });
  }

  // show/hide resource's list of a lesson
  var resourceListDropdowns = document.querySelectorAll('.resources-dropdown');
  if(resourceListDropdowns) {
    resourceListDropdowns.forEach(function(resourceListDropdown) {
      resourceListDropdown.addEventListener('click', function(event) {
        this.nextElementSibling.classList.toggle('is-flex');
        this.querySelector('.dropdown-arrow').classList.toggle('accordion-transform-50');
      });
    });
  }
}

function bindFormationOnTurboAndStreamJs() {
  // fix iframe height
  var lessonFrames = document.querySelectorAll('.lesson-frame');
  if(lessonFrames) {
    lessonFrames.forEach(function(lessonFrame) {
      lessonFrame.onload = function () {
        var contentWindow = lessonFrame.contentWindow;
        // Check if contentWindow is from the same origin
        try {
          var iframeContent = lessonFrame.contentWindow.document.body;
          lessonFrame.style.height = iframeContent.scrollHeight + 'px';
        } catch (e) {
          console.log('Error accessing iframe location:', e);
        }
      }
    })
  }
  // change course sections from main to modal
  const contentContainer = document.getElementById('course-sections-container');
  const dynamicContent = document.getElementById('formation-right-section');
  const modal = document.getElementById('formation-modal-container');
  const modalContentContainer = document.getElementById('modal-course-sections-container');
  const fullscreenButton = document.getElementById('formation-sections-fullscreen-button');
  const closeModalButton = document.getElementById('close-modal');
  
  if(fullscreenButton) {
    // Move the content to modal when full-screen button is clicked
    fullscreenButton.addEventListener('click', function () {
      modalContentContainer.appendChild(dynamicContent); // Move content to modal
      modal.classList.add('is-active'); // Show the modal
    });
  }

  if(closeModalButton) {
    // Move the content back to the original container when closing the modal
    closeModalButton.addEventListener('click', function () {
      contentContainer.appendChild(dynamicContent); // Move content back
      modal.classList.remove('is-active'); // Hide the modal
    });
  }

  // Close the modal if clicking outside the modal content
  window.onclick = function(event) {
    if (event.target === modal) {
      contentContainer.appendChild(dynamicContent); // Move content back
      modal.classList.remove('is-active'); // Hide the modal
    }
  };

  // formation section accordion
  var mainFormationSectionHeaders = document.querySelectorAll('.main-formation-content.formation-section-header');
  if(mainFormationSectionHeaders) {
    mainFormationSectionHeaders.forEach(function(sectionHeader) {
      // this could be binded more than once (related to turbo-stream), so we remove previous binding if needed
      sectionHeader.removeEventListener('click', handleAccordionClick)
      sectionHeader.addEventListener('click', handleAccordionClick);
    });
  }

  // formation section accordion
  var cardFormationSectionHeaders = document.querySelectorAll('.card-formation-content.formation-section-header');
  var cardFormationContents = document.querySelectorAll('.card-formation-content.formation-content');
  var cardSectionChevronIcons = document.querySelectorAll('.card-formation-content.section-chevron-icon');
  if(cardFormationSectionHeaders) {
    cardFormationSectionHeaders.forEach(function(sectionHeader) {
      sectionHeader.addEventListener('click', function(event) {
        if(this.dataset.confirm == '') {
          cardFormationContents.forEach(function(formationContent) {
            formationContent.classList.remove('formation-content-active');
          });
          cardSectionChevronIcons.forEach(function(sectionChevronIcon) {
            sectionChevronIcon.classList.remove('accordion-transform-50');
          });
          this.nextElementSibling.classList.add('formation-content-active');
          this.querySelector('.section-chevron-icon').classList.add('accordion-transform-50');
        }
      });
    });
  }

  // lessons rating forms : bring back the editable fields on click
  var editRatingLink = document.querySelector('.edit-rating');
  var ratingReadonlyFields = document.querySelector('.readonly-fields');
  var ratingEditableFields = document.querySelector('.editable-fields');
  if (editRatingLink && ratingReadonlyFields) {
    editRatingLink.addEventListener('click', function(event) {
      ratingReadonlyFields.classList.add('hidden');
      ratingEditableFields.classList.remove('hidden');
    });
  }

  // show formation left panel for screen smaller then 1366
  var formationLeftContainerLink = document.querySelector('#formation-left-container-link');
  var fermerButtonLink = document.querySelector('#formation-fermer-button-link');
  var formationLeftColumn = document.querySelector('.formation-secondary-content > .left-column');
  if(formationLeftContainerLink && formationLeftColumn) {
    formationLeftContainerLink.addEventListener('click', function(event) {
      formationLeftColumn.style.display = 'block';
    });
    fermerButtonLink.addEventListener('click', function(event) {
      formationLeftColumn.style.display = 'none';
    });
  }

  // show/hide messages and flexes
  var messageTab = document.querySelector('#message-tab');
  var fluxTab = document.querySelector('#flux-tab');
  var messagesContainer = document.querySelector('#messages-container');
  var fluxesContainer = document.querySelector('#fluxes-container');
  if(messageTab && fluxTab) {
    messageTab.addEventListener('click', function(event) {
      setTimeout(() => {
        $(window).scrollTop(380);
      }, 500);
    });
    fluxTab.addEventListener('click', function(event) {
      setTimeout(() => {
        $(window).scrollTop(380);
      }, 500);
      var $fluxes = $('.flux-list .fluxes');
      if ($fluxes.length) {
        $fluxes.scrollTop($fluxes[0].scrollHeight - $fluxes[0].clientHeight);
      }
    });
  }
};
